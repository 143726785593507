import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Card from "../components/card"
import CardNodo from "../components/cardNodo"
import BlueSpan from "../components/blueSpan"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnchor } from "@fortawesome/free-solid-svg-icons"

const nodi = [
  {
    title: "Nodo Semplice",
    filename: "nodi-marinari-semplici.jpg",
    link: "nodi-marinari-di-arresto",
    description:
      "Il nodo semplice (o nodo singolo o collo), come dice il nome, è uno dei più facili nodi da imparare e più veloce da realizzare e costituisce la base da cui partire per la costruzione di nodi più complessi.",
  },
  {
    title: "Nodo Savoia",
    filename: "nodi-marinari-savoia.jpg",
    link: "nodi-marinari-di-arresto",
    description:
      "Questo nodo può essere usato solo come nodo di arresto ma, qualunque sia il materiale da cui è formato il cavo, difficilmente si riuscirà a sfilarlo.",
  },
  {
    title: "Nodo del Cappuccino",
    filename: "nodi-marinari-del-cappuccino.jpg",
    link: "nodi-marinari-di-arresto",
    description:
      "Si esegue facendo sullo stesso anello di corda tre o quattro nodi semplici. Serve per evitare che una corda si sfili da un anello, per appesantire l'estremità di una corda. ",
  },
  {
    title: "Nodo del Francescano",
    filename: "nodi-marinari-del-francescano.jpg",
    link: "nodi-marinari-di-arresto",
    description: "Si differenzia dal Nodo Cappuccino, fatto lungo il cavo, in quanto viene eseguito come terminale. Non si stringe eccessivamente e, quindi, non logora la corda."
  },
]

const DiArresto = () => (
  <Layout>
    <SEO title="Nodi Marinari di Arresto" />
    <h1 className="text-center text-blue-500 font-bold text-3xl mt-12">
      <FontAwesomeIcon
        className="text-blue-600 stroke-current text-black"
        size="lg"
        icon={faAnchor}
      />{" "}
      I nodi marinari d'Arresto{" "}
      <FontAwesomeIcon
        className="text-blue-600 stroke-current text-black"
        size="lg"
        icon={faAnchor}
      />
    </h1>
    <div className="container mx-auto">
      <div className="font-serif shadow p-5 text-justify italic text-xl mt-5">
        I <BlueSpan>nodi marinari di arresto</BlueSpan> si eseguono
        all’estremità dei cavi, allo scopo di impedirne lo sfilamento dai fori o
        bozzelli. <br />
        Esistono 4 tipologie di nodi di arresto tra cui:
        <br />
        {nodi.map(nodo => (
          <CardNodo
            filename={nodo.filename}
            nomeNodo={nodo.title}
            description={nodo.description}
          ></CardNodo>
        ))}
      </div>
      <Card className="mt-5"></Card>

      
    </div>
  </Layout>
)

export default DiArresto
